import React from 'react';
import './testimonial.css';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import { useContext } from 'react';
import { themeContest } from '../../Contest';
import { TestimonialData } from '../../Data/TestimonialData';

import Bubble from '../../Components/Bubbles/Bubble';

const Testimonial = (props) => {
    const theme = useContext(themeContest);
    const darkMode = theme.state.darkMode;
    return (
        <div className={"bg " + (darkMode ? 'bgDark' : '')} >
            <Bubble />
            <motion.div className="testimonial-wrapper"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.5, type: 'linear', stiffness: 200 }}
            >
                <div className={"testimonial " + (darkMode ? 'darktestimonial' : '')} >
                    <h1 style={{ color: props.color }}>testimonial</h1>

                    <div className="testimonialSwiper">
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 2,
                                slideShadows: true,
                            }}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination]}
                            className="mySwiper"
                        >
                            {
                                TestimonialData.map((value, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div className="test">
                                                <div className="testimg">
                                                    <img src={value.Image} alt="" />
                                                </div>
                                                <div className="testtitle">
                                                    <h1 style={{ color: props.color , textShadow:props.color ? `0px 0px 5px ${props.color} ,0px 0px 5px ${props.color}` : ''}}>{value.title}</h1>
                                                </div>
                                                <div className="testdec">
                                                    <p>
                                                        {value.dec}
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )

                                })
                            }

                        </Swiper>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Testimonial
export const ContactData = [
    {
        id: 0,
        text1: 'PAKISTAN: 16/A, Gulshan-e-Iqbal block 10, Karachi, Pakistan.', 
    },
    {
        id: 1,
        text1: 'Pakistan: +92-334-3326007',
        text2: 'Pakistan: +92-300-2111392',
    },
    {
        id:2,
        text1:'Email: info@algotechpro.com',
    }
]
import John from '../Assets/Img/test1.jpeg';
import TestimonialImage1 from '../Assets/Img/test1.avif';
import TestimonialImage2 from '../Assets/Img/testimonial1.avif';
import TestimonialImage3 from '../Assets/Img/testimonial2.avif';
import TestimonialImage4 from '../Assets/Img/testimonial3.avif';
import TestimonialImage5 from '../Assets/Img/testimonial4.avif';

export const TestimonialData = [
    {
        Image:TestimonialImage1,
        title:'Mike Lawrance',
        dec:'Thanks for amazing delivery and friendly advise on IT related issues!'
    },
    {
        Image:TestimonialImage2,
        title:'Drew Hays',
        dec:'I highly recommend him to all those who are looking for Mean Stack Developer for being highly skilled developer, dedicated to his work and client and a very friendly seller.'
    },
    {
        Image:TestimonialImage3,
        title:'Jurica Koletić',
        dec:' Very pleased with the delivery. Understood my request perfectly. Will definitely work together again.'
    },
    {
        Image:TestimonialImage4,
        title:'Cullen Jones',
        dec:'He is a great developer and very responsive! No doubt is the best Mean stack developer in Fiverr !'
    },
    {
        Image:TestimonialImage5,
        title:'Štefan Štefančík',
        dec:'I would highly recommend working with AlgotechPro Team. He is very knowledgeable and reliable.'
    },
    {
        Image:John,
        title:'Johnny Sing',
        dec:'  Excellent Mean Stack Developer! All deliveries from AlgotechPro Team are superior quality.'
    },
]